<template>
    <div>
        <div class="blog-entry" @click="GoToContent()">
            <div class="block-20" :style="'background-image: url('+(Pic != '' && Pic != null ? (imgApiURL + Pic) : imageDefault)+');'"></div>
            <div class="text d-block">
              <div class="meta">
                <p>
                  <a href="#"><span class="fa fa-calendar mr-2"></span>{{DateToString(EventDate)}}</a>
                </p>
              </div>
              <h3 class="heading"><a href="#">{{Name}}</a></h3>
              <p>{{Description}}</p>
              <!-- <p class="mb-0">
                  <a href="#" class="btn-custom d-flex align-items-center justify-content-center">
                  <i class="fa fa-chevron-right"></i><span class="sr-only">Read more</span>
                  </a>
                </p> -->
            </div>
        </div>

    </div>
</template>

<script>

export default {
    props:{
        Name:String,
        Description:String,
        EventDate:String,
        Pic:String,
        Item:Object
    },
    data(){
        return{
            imageDefault: require('../assets/images/image_not_available.png'),
            imgApiURL: this.$bkURL+"imgs/"
        }
    },
    methods:{    
        DateToString(IDate){
            let enMonths =  ["January", "February", "March", "April", "May", "June",
                            "July", "August", "September", "October", "November", "December" ];

            let result = null

            try{
                let Temp = new Date(IDate);
                
                // yyyy-MM-dd
                let year = Temp.getFullYear();
                let month = enMonths[Temp.getMonth()];
                let date = Temp.getDate();

                result = date + " " + month + " " + year;
                // console.log(result);
            }
            catch{
                result = null;
            }
            
            return result;
        },
        GoToContent(){
            if(this.Item != null){
                if(this.Item.descmode == "external"){
                    window.open(this.Item.exturl, '_blank')
                }
                else{
                    this.$router.push("/News/read/"+this.Item.name);
                }
            }
        }
    }
}
</script>

<style scoped>

</style>