<template>
    <div>
        <div class="row">
            <div :class="MiniView ? 'col-sm-12' : 'col-sm-10'">
                <div class="row">
                    <div class="col-sm-4" v-for="(bg,index) in ShowItem.slice(0,SplitIdx)" :key="index">
                        <bg-card
                            v-bind:Name="bg.name"
                            v-bind:Description="bg.description"
                            v-bind:EventDate="bg.eventdate"
                            v-bind:Pic="bg.pic"
                            v-bind:Item="bg"
                        >

                        </bg-card>
                    </div>
                </div>
            </div>

            <div v-if="MiniView != true" class="col-sm-2">
                <div class="row">
                    <b-card class="Cate-Card" no-body>
                        <div class="pt-2 pb-2">
                            <h6>Search</h6>
                            <b-input placeholder="Name" v-model="SearchText"></b-input>
                            <span class="pull-right mt-2">Total: {{ShowItem.length}} news.</span>
                            <!-- <h6 class="mt-2">Category</h6>

                            <div v-for="(cate, ix) in Cates" :key="ix">
                                <b-button class="btn-filter-link" block @click="FilterByCate(cate)" :variant="FilterTag == cate? 'primary' : 'link'">{{cate}}</b-button>
                            </div> -->
                        </div>                        
                    </b-card>                    
                </div>
            </div>
        </div>

        <div class="row" v-if="MiniView == true && Items.length > MinShow">
            <div class="col-sm-12">
                <a href="/News" class="btn btn-secondary pull-right">more</a>
            </div>
        </div>
    </div>
</template>

<script>
import SiteDataService from "../services/SiteDataService.vue"
import BlogCard from "./BlogCard.vue"

export default {
    props:{
        MiniView:Boolean,
    },
    components:{
        "bg-card":BlogCard
    },
    data(){
        return{
            Items:[],
            SplitIdx:3,
            MinShow:3,
            ShowItem:[],
            SearchText:""
        }
    },
    methods:{
        GetItemLists(){
            SiteDataService.NewsGets()
                .then(res => {
                    this.Items = [];
                    this.Items = res.data;
                    this.ShowItem = this.Items;

                    if(this.MiniView != true){
                        this.SplitIdx = this.Items.length;
                    }
                    else{
                        this.SplitIdx = (this.Items.length > this.MinShow ? this.MinShow : this.Items.length)
                    }
                })
                .catch(err => {
                    console.log(err);
                });
        },
        FilterByName(Key){
            let _Key = Key.toLowerCase();
            if(_Key == ""){
                this.ShowItem = this.Items;
                // this.FilterTag = "";
            }
            else{
                this.ShowItem = this.Items.filter(x => x.name.toLowerCase().indexOf(_Key) > -1);                
                // this.FilterTag = Key;
            }
        },
    },
    watch:{
        SearchText(n){
            this.FilterByName(n);
        }
    },
    mounted(){
        this.GetItemLists();
    }
}
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;400;500;600;700&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Reenie+Beanie&amp;display=swap");
@import url("https://fonts.googleapis.com/css2?family=Kanit&display=swap");
</style>